import { useTranslation } from 'react-i18next'
import { AvatarProvider } from '@/components/Elements'
import { BookingModel } from '@/features/bookings/types/models'
import { MessageType } from '@/features/chat/types/models'
import { RatingSubmittedActionMeta } from '@/features/rating/hooks'
import { ClientRatingModel, RatingModel } from '@/features/rating/types/models'
import { Message } from '../Message'
import { Day } from './Day'
import useStyles from './MessageItem.styles'
import { Unread } from './Unread'

interface IProps {
  item: MessageType
  ratingRequest?: boolean
  config: any
  forwardRef: any
  booking?: BookingModel
  onRatingSubmitted?: (rating: any, actionMeta: any) => void
}

export const MessageItem = ({
  item,
  booking,
  onRatingSubmitted,
  config,
  ratingRequest = false,
  forwardRef,
}: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const onHandleRatingSubmitted = (
    rating: ClientRatingModel | RatingModel,
    actionMeta: RatingSubmittedActionMeta
  ) => {
    actionMeta = {
      ...actionMeta,
      payload: {
        bookingId: booking?.id,
      },
    }
    onRatingSubmitted && onRatingSubmitted(rating, actionMeta)
  }

  return (
    <div
      className={cx(classes.root, {
        [`${classes.right}`]: item.is_my && !ratingRequest,
        [`${classes.ratingRequest}`]: ratingRequest,
      })}
      ref={forwardRef}
    >
      <div className={'w-full'}>
        {config.showDate && <Day date={item.created_at} />}

        {config.showUnread && <Unread />}

        <div
          className={cx(classes.messageBlock, {
            [`${classes.right}`]: item.is_my && !ratingRequest,
            [`${classes.ratingRequest}`]: ratingRequest,
          })}
        >
          {!item.is_my && !ratingRequest && (
            <>
              {config.showAvatar ? (
                <div className={classes.avatarGap}>
                  <AvatarProvider size={32} avatar={item?.user?.avatar} />
                </div>
              ) : (
                <div className={classes.avatarHidden} />
              )}
            </>
          )}
          <Message
            item={item}
            ratingRequest={ratingRequest}
            showName={config.showName}
            booking={booking}
            onRatingSubmitted={onHandleRatingSubmitted}
          />
        </div>
      </div>
    </div>
  )
}
