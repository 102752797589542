import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack, Text } from '@mantine/core'
import { ImageView } from '@/components/Elements'
import { useAppState } from '@/features/app/hooks'
import { BookingModel, BookingService } from '@/features/bookings/types/models'
import { MessageType } from '@/features/chat/types/models'
import { RatingEntryPopupManager } from '@/features/rating/components/RatingEntryPopupManager'
import {
  RatingEntryPopupManagerProvider,
  RatingEntryPopupModuleProvider,
} from '@/features/rating/contexts'
import { useRatingEntryPopupManager, useRatingEntryPopupModule } from '@/features/rating/hooks'
import { usePopup } from '@/hooks'
import useStyles from './Message.styles'
import { Meta } from './Meta'

interface IProps {
  item: MessageType
  showName: boolean
  ratingRequest: boolean
  booking?: BookingModel | BookingService
  onRatingSubmitted: (rating: any | null, actionMeta: any) => void
}

export const Message = ({ item, ratingRequest, booking, onRatingSubmitted, showName }: IProps) => {
  const ratingPopupManager = useRatingEntryPopupManager({})
  const { t } = useTranslation()
  const { classes, cx } = useStyles()
  const { visible, open, close } = usePopup()
  const {
    appState: { mobileView },
  } = useAppState()

  const openImage = () => {
    if (mobileView) {
      open(true)
    } else {
      window.open(process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + item?.image, '_blank')
    }
  }

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    ratingPopupManager.navigation.onScreenCreate({ booking })
  }

  const onHandleRatingSubmitted = (rating: any, actionMeta: any) => {
    ratingPopupManager.navigation.onScreenClose()
    onRatingSubmitted(rating, actionMeta)
  }

  const ratingPopupModule = useRatingEntryPopupModule({
    onRatingSubmitted: onHandleRatingSubmitted,
  })

  return (
    <div
      className={cx(classes.root, {
        [`${classes.highlight}`]: item.is_my && !ratingRequest,
        [`${classes.ratingRequest}`]: ratingRequest,
      })}
    >
      {showName && (
        <Text className={classNames('text-start', classes.nameText)}>
          {item.user?.first_name + ' ' + item.user?.last_name}
        </Text>
      )}

      {item?.image && (
        <Group className={classes.image}>
          <img
            className={classes.imageType}
            src={process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + item?.image}
            alt="image"
            onClick={() => openImage()}
          />
        </Group>
      )}
      {item?.body && !ratingRequest && <Text className={'text-start'}>{item?.body}</Text>}
      {item?.body && ratingRequest && (
        <Stack className={'p-3'}>
          <Group className={'gap-1 justify-center'}>
            <Text size={'sm'} className={'text-start'}>
              {item?.body}
            </Text>
            <Text className={'cursor-pointer'} size={'sm'} underline onClick={onClick}>
              {t('chat.rate_booking')}
            </Text>
          </Group>
        </Stack>
      )}

      {!ratingRequest && (
        <div className={'flex justify-end'}>
          <Meta data={item} />
        </div>
      )}
      <ImageView opened={visible} onClose={close}>
        <img
          className={classes.imagePopup}
          src={process.env.REACT_APP_BASE_URL + 'api/chats/chat-image/' + item?.image}
          alt="image"
          onClick={() => openImage()}
        />
      </ImageView>

      <RatingEntryPopupManagerProvider {...ratingPopupManager}>
        <RatingEntryPopupModuleProvider {...ratingPopupModule}>
          <RatingEntryPopupManager />
        </RatingEntryPopupModuleProvider>
      </RatingEntryPopupManagerProvider>
    </div>
  )
}
